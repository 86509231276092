import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const GoogleSignupPopup = ({ onAuthenticated }) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setShowPopup(true);
    } else {
      onAuthenticated();
    }
  }, [onAuthenticated]);

  // Function to exchange code for token
  const exchangeCodeForToken = useCallback(async (code) => {
    try {
      // Send the code to the FastAPI backend to exchange for a token
      const response = await axios.get(`${baseURL}auth/google/callback/?code=${code}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const { access_token } = response.data;
      localStorage.setItem('token', access_token);
      const decodedToken = jwtDecode(access_token); 
      const userName = decodedToken.name; 
      localStorage.setItem('user_name', userName);
      onAuthenticated();
    } catch (error) {
      console.error('Error during token exchange:', error);
    }
  }, [baseURL, onAuthenticated]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      exchangeCodeForToken(code);
      window.history.replaceState({}, document.title, '/');
    }
  }, [exchangeCodeForToken]); 

  const handleGoogleSignup = async () => {
    try {
      const response = await axios.get(`${baseURL}auth/login/`);
      const { redirect_url } = response.data;
      window.location.href = redirect_url;
      navigate('/')

    } catch (error) {
      console.error('Error during Google signup:', error);
    }
  };

  if (!showPopup) return null;

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2 className='google-text'>Continue with Google</h2>
        <button className="btn btn-outline-primary btn-lg mt-4" onClick={handleGoogleSignup}>
          <img src="/images/google.png" alt="Google logo" className="mx-2" />
          Continue with Google
        </button>
      </div>
    </div>
  );
};

export default GoogleSignupPopup;
