import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../layout/navbar';
import GoogleSignupPopup from './googleLogin';

const ChatApp = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [notification, setNotification] = useState('');
  const [showTiles, setShowTiles] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const name = localStorage.getItem('user_name');
  const apiEndpoint = `${baseURL}chat/completion/`;
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (!isAuthenticated) {
      setShowTiles(false);
    }
  }, [isAuthenticated]);

  const handleAuthenticated = () => {
    setIsAuthenticated(true);
    setShowTiles(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(''), 3000);
  };

  const addMessage = (content, isUser = true, isHTML = false) => {
    const newMessage = {
      text: content,
      type: isUser ? 'user' : 'system',
      isHTML,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    scrollToBottom();
  };

  const sendMessageToAPI = async (message) => {
    addMessage(message, true);
    setInputValue('');
    addMessage('Thinking...', false);
    setLoading(true);

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json',
        },
        body: JSON.stringify({ message }),
      });
      if (response.status === 401) {
        setErrorMessage('Unauthorized. Please log in again.');
      }

      const data = await response.json();

      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.text !== 'Thinking...')
      );
      const isHTML = /<\/?[a-z][\s\S]*>/i.test(data.response);
      addMessage(data.response, false, isHTML);

      scrollToBottom();
    } catch (error) {
      console.error('Error:', error);
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.text !== 'Thinking...')
      );
      addMessage('An error occurred. Please try again.', false);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value.trim()) {
      setShowTiles(false);
    }
  };

  const handleSendMessage = () => {
    scrollToBottom();
    if (inputValue.trim() && !loading) {
      sendMessageToAPI(inputValue.trim());
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !loading) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode');
  };

  const copyChat = () => {
    const chatText = messages
      .map((msg) => `${msg.type === 'user' ? name || 'User' : 'Robo'}: ${msg.text}`)
      .join('\n\n');
    navigator.clipboard.writeText(chatText).then(() => {
      showNotification('Chat copied to clipboard!');
    });
  };

  const clearChat = () => {
    setMessages([]);
  };

  const handleTileClick = (message) => {
    setShowTiles(false);
    sendMessageToAPI(message);
  };

  return (
    <div className={`app ${isDarkMode ? 'dark-mode' : ''}`}>
      {!isAuthenticated && <GoogleSignupPopup onAuthenticated={handleAuthenticated} />}
      <Navbar
        copyChat={copyChat}
        toggleDarkMode={toggleDarkMode}
        isDarkMode={isDarkMode}
        clearChat={clearChat}
        handleLogout={handleLogout}
      />

      {showTiles && (
        <div className="tile-container flex-grow-1" id="tileContainer">
          <div className="tile" onClick={() => handleTileClick('Show me Top News of the Year')}>
            Show me Top News of the Year
          </div>
          <div className="tile" onClick={() => handleTileClick('What Happened in China in Last 7 Days')}>
            What Happened in China in Last 7 Days
          </div>
          <div className="tile" onClick={() => handleTileClick('Message to comfort a friend')}>
            Message to comfort a friend
          </div>
          <div className="tile" onClick={() => handleTileClick('How to Overcome procrastination')}>
            How to Overcome procrastination
          </div>
        </div>
      )}

      <div className="container flex-grow-1">
        <div className="chat-container" id="chatContainer" ref={chatContainerRef}>
          {errorMessage && <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}
          {messages.map((msg, index) => (
            <div key={index} className={`message-group ${msg.type}-group`}>
              <div className="message-label">
                {msg.type === 'user' ? (name || 'User') : 'Robo'}
              </div>
              <div className={`chat-message ${msg.type === 'user' ? 'user-message' : 'system-message'}`}>
                {msg.isHTML ? (
                  <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                ) : (
                  msg.text
                )}
                {msg.type === 'user' && (
                  <button
                    className="copy-button"
                    onClick={() => navigator.clipboard.writeText(msg.text)}
                  >
                    📋
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {notification && <div className="notification">{notification}</div>}

      <div className="input-container">
        <div className="container">
          <div className="input-group">
            <textarea
              className="form-control chat-input"
              id="chatInput"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Ask Robo anything..."
              aria-label="Chat input"
              disabled={loading}
            />
            <button className="btn btn-primary" onClick={handleSendMessage} disabled={loading}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
