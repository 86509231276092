import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChatApp from './Components/chatApp';


function App() {
  return (

    <Router>
    <Routes>
      <Route path="/" element={<ChatApp />} />
      <Route path="/auth/google/callback/" element={<ChatApp />} />
    </Routes>
  </Router>
  );
}

export default App;
